import React from 'react';
import propTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Replay } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import BorderlessButtonStyles from './BorderlessButtonStyles.styles';

const useStyles = makeStyles(BorderlessButtonStyles);

export const BorderlessButton = ({ onClick, startIcon, title }) => {
  const classes = useStyles();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button className={classes.resetButton} startIcon={startIcon} onClick={handleClick}>
      {title}
    </Button>
  );
};

BorderlessButton.propTypes = {
  onClick: propTypes.func,
  startIcon: propTypes.node,
  title: propTypes.object.isRequired,
};

BorderlessButton.defaultProps = {
  startIcon: <Replay />,
  onClick: null,
};
