import React from 'react';
import propTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import ActionButtonStyles from './ActionButtonStyles.styles';

const useStyles = makeStyles(ActionButtonStyles);

export const ActionButton = ({ disabled, onClick, buttonTitle, variant, startIcon, color, borderColor }) => {
  const classes = useStyles();

  return (
    <Button
      variant={variant}
      color={color}
      className={classes.ActionButton}
      style={{ borderColor }}
      startIcon={startIcon}
      disabled={disabled}
      onClick={onClick}
    >
      {buttonTitle}
    </Button>
  );
};

ActionButton.propTypes = {
  disabled: propTypes.bool,
  onClick: propTypes.func.isRequired,
  buttonTitle: propTypes.object.isRequired,
  variant: propTypes.string,
  startIcon: propTypes.element,
  color: propTypes.string,
  borderColor: propTypes.string,
};

ActionButton.defaultProps = {
  disabled: false,
  variant: 'contained',
  startIcon: <Search />,
  color: 'primary',
  borderColor: 'primary',
};
