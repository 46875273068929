const TrafficInsightsStyles = (theme) => ({
  mainContainer: {
    paddingTop: 19,
  },
  dropDownContainer: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  icon: {
    fontSize: 20,
  },
  gridContainer: {
    marginRight: 10,
  },
  formControl: {
    display: 'block',
    marginTop: 20,
  },
  formControlLabel: {
    fontSize: 14,
    marginBottom: 2,
  },
  buttonOk: {
    display: 'block',
    margin: '0 auto',
    marginTop: 20,
  },
  selectLabel: {
    border: `1px solid ${theme.color02}`,
    borderRadius: 5,
    padding: '8px 16px',
    cursor: 'pointer',
  },
  selectText: {
    fontSize: 14,
    fontWeight: 500,
    paddingRight: 8,
    textTransform: 'uppercase',
    marginLeft: 10,
  },
  arrowDown: { marginLeft: 'auto' },
  arrowUp: { marginLeft: 'auto' },
  selectList: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    background: theme.colorWhite,
    border: `1px solid ${theme.color02}`,
    borderRadius: 5,
    padding: '8px 16px',
    zIndex: 10,
  },
  horizontalLine: {
    borderTop: `1px solid ${theme.color02}`,
  },
  hoursTooltips: {
    fontSize: 11,
    textAlign: 'center',
    margin: 0,
  },
  circularProgress: {
    margin: '0 auto',
    display: 'block',
  },
  circularProgressSmall: {
    margin: '12px auto',
    display: 'block',
  },
  calendarIcon: {
    position: 'absolute',
    left: 15,
    top: 10,
    pointerEvents: 'none',
  },

  collapseSideBarButton: {
    width: 26,
    height: 56,
    backgroundColor: theme.color01,
    position: 'absolute',
    top: 74,
    left: 410,
    zIndex: 999,
    cursor: 'pointer',
    transition: 'left .35s ease-in-out',
    '.sidebarHidden &': {
      left: 0,
    },
  },
  insightsButton: {
    minWidth: 160,
    marginTop: 10,
    '&, &:hover': {
      boxShadow: `0px 0px 5px 0px ${theme.color109}`,
    },
    '&:hover': {
      backgroundColor: theme.color01,
    },
  },
  signalPlanButton: {
    minWidth: 160,
    marginTop: 10,
    transition: 'right .35s ease-in-out',
    textTransform: 'none',
    '&, &:hover': {
      boxShadow: `0px 0px 5px 0px ${theme.color109}`,
    },
    '&:hover': {
      backgroundColor: theme.color01,
    },
  },
  signalPlanButtonDisabled: {
    backgroundColor: `${theme.colorWhite} !important`,
  },
  insightButtonIcon: {
    position: 'relative',
    top: -3,
    transform: 'rotate(180deg)',
  },
  arrowChevron: {
    position: 'absolute',
    top: 8,
    left: 11,
    transition: 'transform 0.2s ease-in-out',
  },
  arrowChevronDown: {
    transform: 'rotate(0deg)',
  },
  arrowChevronUp: {
    transform: 'rotate(180deg)',
  },
  selectionButton: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export default TrafficInsightsStyles;
