import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { BottomContainer, TotalRidesTable, FramesSlider } from 'components';
import {
  selectExpandedPanel,
  selectCurrentTotalRides,
  selectCorridors,
  fetchApproaches,
  fetchGeneralMetricsFrames,
  selectGeneralMetricsFrames,
  selectLoadingGeneralMetricsFrames,
  selectApproaches,
  resetTotalRidesApproaches,
  resetActiveFrameCords,
} from 'features';
import { maps, layers, initLayer, layerStyles } from 'utils';
import { usePostData } from 'utils/hooks';

export const TotalRidesContainer = ({ rides }) => {
  const { framesPostData } = usePostData();
  const dispatch = useDispatch();
  const expandedPanel = useSelector(selectExpandedPanel);
  const currentTotalRides = useSelector(selectCurrentTotalRides);
  const { data, selectedCorridorIndex } = useSelector(selectCorridors);
  const allMetricFrames = useSelector(selectGeneralMetricsFrames);
  const loadingFrames = useSelector(selectLoadingGeneralMetricsFrames);
  const approaches = useSelector(selectApproaches);
  const [framesKey, setFramesKey] = useState(null);
  const frames = allMetricFrames[framesKey];
  const corridorId = data[selectedCorridorIndex]?.features[0].properties.corridorId;
  const olMap = maps.TrafficInsights;

  const onRideClick = async (ride) => {
    if (process.env.REACT_APP_MAP_CORRIDOR_VIEW === 'openlayers') {
      olMap.removeLayer(layers.rideSegments);
    }

    if (process.env.REACT_APP_MAP_CORRIDOR_VIEW === 'mapbox') {
      dispatch(resetTotalRidesApproaches());
      dispatch(resetActiveFrameCords());
    }

    dispatch(
      fetchApproaches({
        fromIntersectionId: ride.startIntersectionId,
        toIntersectionId: ride.endIntersectionId,
        corridorId,
      }),
    );

    const postData = framesPostData({
      cameraId: ride.cameraId,
      startDate: ride.startRideDate,
      endDate: ride.endRideDate,
    });

    dispatch(fetchGeneralMetricsFrames({ postData }));
    setFramesKey(JSON.stringify(postData));
  };

  useEffect(() => {
    setFramesKey(null);
  }, [expandedPanel, currentTotalRides]);

  useEffect(() => {
    if (!olMap || !approaches) return;
    layers.rideSegments = initLayer(
      'rides-approaches',
      approaches,
      (feature) => layerStyles.corridorView.ridesSegments(feature),
      2,
    );

    olMap.addLayer(layers.rideSegments);

    olMap
      .getView()
      .fit(olMap.getLayers().getArray()[4].getSource().getExtent(), { duration: 1000, padding: [50, 50, 450, 50] });
  }, [olMap, approaches]);

  useEffect(() => {
    if (expandedPanel === 'total-rides' || process.env.REACT_APP_MAP_CORRIDOR_VIEW !== 'mapbox') return;
    dispatch(resetTotalRidesApproaches());
    dispatch(resetActiveFrameCords());
  }, [expandedPanel]); // eslint-disable-line

  return (
    <BottomContainer
      slideIn={expandedPanel === 'total-rides'}
      canCollapseTable
      mainContent={<TotalRidesTable totalRides={rides} onRideClick={onRideClick} />}
      mediaContent={
        <FramesSlider
          framesLists={[frames]}
          showFrameDetails
          isLoading={loadingFrames === 'loading'}
          showNotFound={loadingFrames === 'error'}
          fillParentHeight
        />
      }
    />
  );
};

TotalRidesContainer.propTypes = {
  rides: propTypes.object.isRequired,
};
