import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { useIntl } from 'utils/hooks';
import { selectIsUserAdmin, selectMetricType } from 'features';
import { boundingBoxTypes, metricTypes } from 'consts';
import theme from 'theme';
import styles from './FrameCanvas.module.scss';

const getDetectionTypes = (intl) => {
  const detectionTypes = new Map();

  detectionTypes.set(boundingBoxTypes.PERSON, {
    text: intl.formatMessage({ defaultMessage: 'Pedestrian Detection', description: 'Detection type' }),
    color: theme.color25,
  });
  detectionTypes.set(boundingBoxTypes.BICYCLE, {
    text: intl.formatMessage({ defaultMessage: 'Cyclist Detection', description: 'Detection type' }),
    color: theme.color57,
  });
  detectionTypes.set(boundingBoxTypes.FACE_MASK_USAGE, {
    text: intl.formatMessage({ defaultMessage: 'Face Mask Worn', description: 'Detection type' }),
    color: theme.color25,
  });
  detectionTypes.set(boundingBoxTypes.NO_FACE_MASK_USAGE, {
    text: intl.formatMessage({ defaultMessage: 'Face Mask Not Worn', description: 'Detection type' }),
    color: theme.color26,
  });
  detectionTypes.set(boundingBoxTypes.SCOOTER, {
    text: intl.formatMessage({ defaultMessage: 'Scooter Detection', description: 'Detection type' }),
    color: theme.color42,
  });
  detectionTypes.set(boundingBoxTypes.MOTORBIKE, {
    text: intl.formatMessage({ defaultMessage: 'Motorbike Detection', description: 'Detection type' }),
    color: theme.color53,
  });
  detectionTypes.set(boundingBoxTypes.REFUGE_ISLAND, {
    text: intl.formatMessage({ defaultMessage: 'Crowded Refuge Island Detected', description: 'Detection type' }),
    color: theme.color62,
  });

  return detectionTypes;
};

const getMetricTypesBoxes = (tags) => {
  const metricTypesBoxes = new Map();

  metricTypesBoxes.set(metricTypes.TOTAL_DENSITY, [
    boundingBoxTypes.PERSON,
    boundingBoxTypes.BICYCLE,
    boundingBoxTypes.SCOOTER,
  ]);
  metricTypesBoxes.set(metricTypes.PEDESTRIAN_DENSITY, [boundingBoxTypes.PERSON]);
  metricTypesBoxes.set(metricTypes.SCOOTER_DENSITY, [boundingBoxTypes.SCOOTER]);
  metricTypesBoxes.set(metricTypes.CYCLIST_DENSITY, [boundingBoxTypes.BICYCLE]);
  metricTypesBoxes.set(metricTypes.FACE_MASK_USAGE, [
    boundingBoxTypes.FACE_MASK_USAGE,
    boundingBoxTypes.NO_FACE_MASK_USAGE,
  ]);
  metricTypesBoxes.set(metricTypes.PASSENGERS_DENSITY, [
    boundingBoxTypes.PERSON,
    boundingBoxTypes.BICYCLE,
    boundingBoxTypes.SCOOTER,
  ]);
  if (tags.includes('activeCrosswalk')) {
    metricTypesBoxes.set(metricTypes.ACTIVE_CROSSWALK, [
      boundingBoxTypes.PERSON,
      boundingBoxTypes.BICYCLE,
      boundingBoxTypes.SCOOTER,
    ]);
  }
  if (tags.includes('activeCrosswalk') && tags.includes('green')) {
    metricTypesBoxes.set(metricTypes.PEDESTRIANS_SIGNAL_PROGRESSION, [
      boundingBoxTypes.PERSON,
      boundingBoxTypes.BICYCLE,
      boundingBoxTypes.SCOOTER,
    ]);
  }
  if (tags.includes('wildPedestrian')) {
    metricTypesBoxes.set(metricTypes.JAYWALKERS, [
      boundingBoxTypes.PERSON,
      boundingBoxTypes.BICYCLE,
      boundingBoxTypes.SCOOTER,
    ]);
  }
  if (tags.includes('refugeIsland')) {
    metricTypesBoxes.set(metricTypes.RED_LIGHT, [boundingBoxTypes.REFUGE_ISLAND]);
  }

  return metricTypesBoxes;
};

export const FrameCanvas = ({ boundingBoxes, tags, imgRef }) => {
  const intl = useIntl();
  const canvasRef = useRef(null);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const metricType = useSelector(selectMetricType);
  const detectionTypes = getDetectionTypes(intl);
  const metricTypesBoxes = getMetricTypesBoxes(tags);
  const visibleTypes = metricTypesBoxes.get(metricType);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const typesInFrame = [];
    const fontSize = 20;

    canvas.width = imgRef.current.naturalWidth;
    canvas.height = imgRef.current.naturalHeight;

    if (!visibleTypes) return;
    context.font = `bold ${fontSize}px Arial`;
    boundingBoxes.forEach((box) => {
      if (!isUserAdmin && !visibleTypes.includes(box.type)) return;
      context.save();
      const detectionTypeText = detectionTypes.get(box.type).text;
      const detectionTypeColor = detectionTypes.get(box.type).color;

      context.fillStyle = detectionTypeColor;
      context.globalAlpha = 0.4;
      context.fillRect(box.topLeftX, box.topLeftY, box.bottomRightX - box.topLeftX, box.bottomRightY - box.topLeftY);
      if (!typesInFrame.includes(box.type)) {
        const count = boundingBoxes.filter((boundingBox) => boundingBox.type === box.type).length;
        const legendTitle = isUserAdmin ? `${detectionTypeText} (${count})` : detectionTypeText;

        typesInFrame.push(box.type);
        context.fillRect(10, (typesInFrame.length + 2) * fontSize + 5, 15, 15);
        context.fillStyle = theme.colorWhite;
        context.lineWidth = '1.5';
        context.globalAlpha = 1;
        context.fillText(legendTitle, 30, fontSize * (typesInFrame.length + 3));
        context.strokeText(legendTitle, 30, fontSize * (typesInFrame.length + 3));
      }
      context.restore();
    });
  }, []); //eslint-disable-line

  return <canvas ref={canvasRef} className={styles.canvas} />;
};

FrameCanvas.propTypes = {
  boundingBoxes: propTypes.array.isRequired,
  tags: propTypes.array.isRequired,
  imgRef: propTypes.object.isRequired,
};
