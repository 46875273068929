import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCorridors, setSelectedCorridorIndex } from 'features';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as DoubleChevron } from 'assets/images/double-chevron.svg';
import { ReactComponent as DoubleChevronGray } from 'assets/images/double-chevron-gray.svg';

import SelectCorridorsStyles from './SelectCorridors.styles';

const useStyles = makeStyles(SelectCorridorsStyles);

export const SelectCorridors = ({ resetQueriedInput, disabled }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { corridorsNames, selectedCorridorIndex } = useSelector(selectCorridors);
  const [isListOfCorridorsVisible, setIsListOfCorridorsVisible] = useState(false);
  const hasCorridorSelected = typeof corridorsNames[selectedCorridorIndex]?.corridorName === 'string';
  const ChevronIcon = disabled ? DoubleChevronGray : DoubleChevron;

  const onCorridorsListClick = () => {
    if (!disabled) setIsListOfCorridorsVisible(!isListOfCorridorsVisible);
  };

  const onCorridorsListMouseLeave = () => {
    setIsListOfCorridorsVisible(false);
  };

  const onCorridorsListItemClick = (corridorName, i) => () => {
    dispatch(setSelectedCorridorIndex(i));
    setIsListOfCorridorsVisible(false);
    if (resetQueriedInput) resetQueriedInput();
  };

  return (
    <div className={classes.corridorsList}>
      <div
        className={`${classes.corridorsTitle} ${disabled ? classes.corridorsTitleDisabled : ''}`}
        onClick={onCorridorsListClick}
        data-testid="open-menu"
      >
        <ChevronIcon
          className={`${classes.arrowChevron} ${
            isListOfCorridorsVisible ? classes.arrowChevronUp : classes.arrowChevronDown
          }`}
        />
        <p>
          {hasCorridorSelected ? (
            corridorsNames[selectedCorridorIndex]?.corridorName
          ) : (
            <FormattedMessage defaultMessage="SELECT CORRIDOR" description="Select corridor button message" />
          )}
        </p>
      </div>
      {isListOfCorridorsVisible && (
        <div
          className={classes.corridorsInnerList}
          onMouseLeave={onCorridorsListMouseLeave}
          data-testid="corridors-list"
        >
          {corridorsNames.map(({ corridorName }, i) => (
            <p
              key={`${corridorName}`}
              onClick={onCorridorsListItemClick(corridorName, i)}
              data-testid="select-corridor"
              className={`${selectedCorridorIndex === i ? classes.selectedCorridor : ''}`}
            >
              {corridorName}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

SelectCorridors.propTypes = {
  resetQueriedInput: propTypes.func,
  disabled: propTypes.bool,
};

SelectCorridors.defaultProps = {
  resetQueriedInput: undefined,
  disabled: false,
};
