const BorderlessButtonStyles = (theme) => ({
  resetButton: {
    padding: '10px 0',
    textTransform: 'none',
    fontSize: 13,
    color: theme.color08,
    width: '100%',
    borderRadius: 0,
  },
});

export default BorderlessButtonStyles;
