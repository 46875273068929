import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TabbedMenus } from 'components';
import {
  selectStatisticType,
  setStatisticType,
  setMetricType,
  selectIsUserAdmin,
  resetActiveFrameCords,
} from 'features';
import { GENERAL_VIEW_MENUS, getGeneralViewTabs, metricTypes } from 'consts';

export const StatisticsMenu = () => {
  const dispatch = useDispatch();
  const statisticType = useSelector(selectStatisticType);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const tabs = getGeneralViewTabs(isUserAdmin);
  const menus = GENERAL_VIEW_MENUS;
  const hiddenMenus = isUserAdmin
    ? []
    : [
        metricTypes.JAYWALKERS,
        metricTypes.PEDESTRIANS_SIGNAL_PROGRESSION,
        metricTypes.FACE_MASK_USAGE,
        metricTypes.SCOOTER_DENSITY,
      ];
  const initialTab = tabs.find(({ name }) => name === statisticType);

  const activeTabHandler = (activeTab) => {
    dispatch(setStatisticType(activeTab.name));
  };

  const activeMenuHandler = async (menuItem) => {
    dispatch(setMetricType(menuItem.name));
    dispatch(resetActiveFrameCords());
  };

  return (
    <>
      <TabbedMenus
        {...{
          tabs,
          menus,
          activeTabHandler,
          activeMenuHandler,
          initialTab,
          hiddenMenus,
        }}
      />
    </>
  );
};
