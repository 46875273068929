import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsLoading } from 'features';
import { CircularProgress } from '@material-ui/core';
import styles from './LoadingOverlay.module.scss';

export const LoadingOverlay = () => {
  const isLoading = useSelector(selectIsLoading);

  if (!isLoading) {
    return null;
  }

  return (
    <div className={styles.root}>
      <CircularProgress className={styles.spinner} size={60} thickness={5} />
    </div>
  );
};
