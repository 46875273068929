import axios from 'axios';
import { API_BASE_URL } from 'consts';

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const defaultOptions = {
  withCredentials: true,
  validateStatus: false,
};

const requester = (method) => (url, data = {}, headers = {}, options) =>
  axios({
    baseURL: API_BASE_URL,
    method,
    url,
    data,
    ...defaultHeaders,
    headers,
    ...defaultOptions,
    ...options,
  });

export const get = requester('get');
export const post = requester('post');
export const del = requester('delete');
export const promiseAll = (promises = []) => axios.all(promises);
