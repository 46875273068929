const CollapseButtonStyles = {
  collapseArrowIcon: {
    width: 10,
    height: 13,
    padding: '22px 8px',
    transition: 'transform 0.2s ease-in-out',
    transform: 'rotate(0deg)',
    '&.collapseArrowLeft': {
      transform: 'rotate(180deg)',
    },
  },
};

export default CollapseButtonStyles;
