const ActionButtonStyles = {
  ActionButton: {
    margin: 0,
    padding: '10px 0',
    width: '100%',
    borderRadius: 5,
    textTransform: 'none',
  },
};

export default ActionButtonStyles;
