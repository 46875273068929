import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectGeneralViewRadialChart, selectUnitSystemName } from 'features';
import { MediaBox } from 'components';
import { findFeatureColor } from 'utils';
import Slide from '@material-ui/core/Slide';
import { XYPlot, ArcSeries } from 'react-vis';
import theme from 'theme';
import styles from './GeneralViewRadialChart.module.scss';

const directionCodes = {
  metric: ['N', 'E', 'S', 'W', 'SE', 'SW', 'NE', 'NW'],
  imperial: ['SB', 'WB', 'NB', 'EB', 'NWB', 'NEB', 'SWB', 'SEB'],
};

export const GeneralViewRadialChart = () => {
  const chartData = useSelector(selectGeneralViewRadialChart);
  const unitSystemName = useSelector(selectUnitSystemName);
  const maxDegrees = 270;
  const scalePercents = [0, 25, 50, 75, 100];
  const scale = scalePercents.map((percent) => ({
    text: `${percent}%`,
    degrees: 270 / (100 / percent) - 90,
  }));
  const [arcsData, chartItems] = useMemo(() => {
    if (!chartData) return [null, []];
    const sortedItems = chartData.items
      .slice()
      .sort((a, b) => directionCodes.metric.indexOf(a.directionCode) - directionCodes.metric.indexOf(b.directionCode));

    const highestValue = sortedItems.slice().sort((a, b) => b.value - a.value)[0].value;
    const values = sortedItems.map(({ value }) => (highestValue > 1 ? value * (1 / highestValue) : value));

    const start = 2.9;
    const step = 0.5;
    const maxRadiant = (maxDegrees * Math.PI) / 180;

    const arcs = values.reduce((acc, value, index) => {
      const color = findFeatureColor({
        metricValue: value * 100,
        metricType: chartData.metricType,
      });

      acc.push({
        angle: maxRadiant,
        radius0: start - index * step,
        radius: start - index * step,
        color: theme.color13,
      });
      if (index === values.length - 1) {
        acc.push({
          angle: maxRadiant,
          radius0: start - (index + 1) * step,
          radius: start - (index + 1) * step,
          color: theme.color13,
        });
      }
      acc.push({
        angle: value * maxRadiant,
        radius0: start - (step - 0.1) - index * step,
        radius: start - 0.1 - index * step,
        color,
      });

      return acc;
    }, []);

    return [arcs, sortedItems];
  }, [chartData]);

  return (
    <Slide
      in={Array.isArray(arcsData)}
      direction="up"
      mountOnEnter
      unmountOnExit
      timeout={{
        enter: 300,
        exit: 0,
      }}
    >
      <div className={styles.root}>
        <MediaBox
          className={styles.mediaBox}
          headerContent={
            <>
              <div>{chartData?.approachName}</div>
              <div>{chartData?.title}</div>
            </>
          }
        >
          <div className={styles.chart}>
            <div className={styles.labels}>
              {chartItems.map(({ directionCode }) => (
                <div className={styles.label} key={directionCode}>
                  {directionCodes[unitSystemName][directionCodes.metric.indexOf(directionCode)]}
                </div>
              ))}
            </div>
            {scale.map(({ text, degrees }) => (
              <div
                className={styles.scaleItem}
                key={text}
                style={{
                  transform: `rotate(${degrees}deg)`,
                  clipPath: `inset(-30px -30px -30px ${120 - chartData?.items.length * 21}px)`,
                }}
              >
                <div
                  className={styles.scaleItemText}
                  style={{ transform: `translateY(-50%) rotate(${degrees * -1}deg` }}
                >
                  {text}
                </div>
              </div>
            ))}
            <XYPlot xDomain={[-1, 1.25]} yDomain={[-2.6, 3]} width={300} height={300}>
              <ArcSeries colorType="literal" radiusDomain={[0, 3]} data={arcsData} />
            </XYPlot>
          </div>
        </MediaBox>
      </div>
    </Slide>
  );
};
