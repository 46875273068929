const SelectCorridors = (theme) => ({
  arrowChevronUp: {
    transform: 'rotate(180deg)',
  },
  arrowChevron: {
    position: 'absolute',
    top: 8,
    left: 11,
    transition: 'transform 0.2s ease-in-out',
  },
  arrowChevronDown: {
    transform: 'rotate(0deg)',
  },
  corridorsList: {
    position: 'relative',
    marginTop: 10,
  },
  corridorsTitle: {
    width: 160,
    backgroundColor: theme.color01,
    borderRadius: 4,
    cursor: 'pointer',
    boxShadow: `0px 0px 5px 0px ${theme.color109}`,
    position: 'relative',

    '& p': {
      color: theme.colorWhite,
      fontWeight: 700,
      fontSize: 12,
      textTransform: 'none',
      textAlign: 'center',
      margin: 0,
      padding: '14px 0 12px 40px',
      display: 'inline-block',
    },
  },
  corridorsTitleDisabled: {
    backgroundColor: theme.colorWhite,
    boxShadow: 'none',
    cursor: 'default',
    '& p': {
      color: theme.color02,
    },
  },
  corridorsInnerList: {
    position: 'absolute',
    right: 0,
    top: 27,
    zIndex: 1,
    width: 160,
    backgroundColor: theme.colorWhite,
    borderRadius: 9,
    marginTop: 15,
    boxShadow: `0px 0px 5px 0px ${theme.color109}`,
    maxHeight: 350,
    overflow: 'auto',

    '& p': {
      fontSize: 13,
      color: theme.color113,
      padding: 20,
      margin: 0,
      fontWeight: 500,
      borderRadius: 9,
      '&:hover': {
        backgroundColor: theme.color03,
        color: theme.color01,
        cursor: 'pointer',
      },
    },
  },
  selectedCorridor: {
    backgroundColor: theme.color03,
    color: `${theme.color01} !important`,
  },
});

export default SelectCorridors;
