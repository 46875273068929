import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Typography, Box, ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { FormattedMessage } from 'react-intl';
import { selectUser, selectIsUserAdmin } from 'features';
import { TenantSelect, UnitSystemSelect } from 'components';
import { ReactComponent as DefaultProfilePicture } from 'assets/images/default-profile.svg';
import { API_BASE_URL, LOG_OUT } from 'consts';
import UserMenuStyles from './UserMenu.styles';

const useStyles = makeStyles(UserMenuStyles);

export const UserMenu = ({ position }) => {
  const classes = useStyles();
  const user = useSelector(selectUser);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const [areProfileDetailsVisible, setAreProfileDetailsVisible] = useState(false);
  const logOutUrl = `https://login.windows.net/common/oauth2/logout?post_logout_redirect_uri=${API_BASE_URL}/${LOG_OUT}`;
  const ProfilePicture = user.profilePicture || DefaultProfilePicture;

  const toggleProfileDetailsVisible = () => {
    setAreProfileDetailsVisible(!areProfileDetailsVisible);
  };

  const closeProfileDetails = () => {
    setAreProfileDetailsVisible(false);
  };

  const onSignOutClick = () => {
    setAreProfileDetailsVisible(false);
    window.location.assign(logOutUrl);
  };

  return (
    <>
      <ProfilePicture
        data-testid="avatar"
        className={classes.userProfilePicture}
        onClick={toggleProfileDetailsVisible}
      />
      {areProfileDetailsVisible && (
        <ClickAwayListener onClickAway={closeProfileDetails} mouseEvent="onMouseDown">
          <Box className={`${classes.profileInfoBox} ${position}`} data-testid="dropdown">
            <CloseIcon onClick={closeProfileDetails} className={classes.closeIcon} />
            <ProfilePicture className={classes.userProfilePictureInner} />
            <Typography className={classes.fullName}>{user.fullName}</Typography>
            {isUserAdmin && <TenantSelect />}
            <UnitSystemSelect />
            <Button
              variant="outlined"
              color="secondary"
              className={`${classes.signOutButton}`}
              onClick={onSignOutClick}
              data-testid="logout"
            >
              <FormattedMessage defaultMessage="Sign out" description="User profile dropdown sign out buttons" />
            </Button>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};

UserMenu.propTypes = {
  position: propTypes.oneOf(['top-left', 'top-right']),
};

UserMenu.defaultProps = {
  position: 'top-left',
};
