import React, { useState, useEffect, useLayoutEffect, useReducer, useCallback, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { FrameCanvas } from 'components';
import {
  selectIsUserAdmin,
  setActiveFrameCords,
  setActiveFrameCordsAsync,
  setActiveFrameDetails,
  setFrameDetailsOverlay,
} from 'features';
import { preloadImage } from 'utils';
import { useFormat } from 'utils/hooks';
import { CircularProgress, Modal, Backdrop, Fade } from '@material-ui/core';
import {
  PlayCircleFilled,
  Pause,
  PlayArrow,
  FastForward,
  Fullscreen,
  FullscreenExit,
  InfoOutlined,
} from '@material-ui/icons';
import { ReactComponent as VideoIcon } from 'assets/images/video-icon.svg';
import imageNotAvailable from 'assets/images/Image not available.@2x.png';
import styles from './FramesSlider.module.scss';

export const FramesSlider = ({
  framesLists,
  listIndexChangedHandler,
  isLoading: isLoadingFrames,
  showFrameDetails,
  showInfoButton,
  showBoxes,
  showTags,
  showNotFound,
  fillParentHeight,
  frameTimePosition,
  overlayContent,
  bottomRightContent,
  listIndex: _listIndex,
}) => {
  const { fromUtcToProjectTime } = useFormat();
  const dispatch = useDispatch();
  const rootElementRef = useRef(null);
  const playPauseButtonRef = useRef(null);
  const nextFrameButtonRef = useRef(null);
  const prevFrameButtonRef = useRef(null);
  const nextFrameTimeout = useRef(null);
  const frameImgRef = useRef(null);
  const showFrameSpinnerTimeout = useRef(null);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const [frameTimeDetails, setFrameTimeDetails] = useState({});
  const [frameLoadError, setFrameLoadError] = useState(null);
  const [showFrameSpinner, setShowFrameSpinner] = useState(false);
  const [frameIsLoaded, setFrameIsLoaded] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const showOverlayContent = overlayContent && !isLoadingFrames;
  const notFound = frameLoadError || showNotFound;
  const nextListIndex = (current) => (current >= framesLists.length - 1 ? 0 : current + 1);
  const prevListIndex = (current) => (current === 0 ? framesLists.length - 1 : current - 1);

  const [listIndex, changeListIndex] = useReducer((current, action) => {
    if (action === 'increment') {
      return nextListIndex(current);
    }
    if (action === 'decrement') {
      return prevListIndex(current);
    }
    if (typeof action === 'number') {
      return action;
    }

    return false;
  });

  const frames = useMemo(() => framesLists[listIndex] || [], [framesLists, listIndex]);

  const forwardFrameIndex = (current, step = 1) => {
    if (step >= frames.length) {
      return current;
    }

    return current + step <= frames.length - 1 ? current + step : current + step - frames.length;
  };

  const backwardFrameIndex = (current, step = 1) => {
    if (step >= frames.length) {
      return current;
    }

    return current - step >= 0 ? current - step : current - step + frames.length;
  };

  const [frameIndex, changeFrameIndex] = useReducer((current, action) => {
    switch (action) {
      case 'increment':
        return forwardFrameIndex(current);
      case 'decrement':
        return backwardFrameIndex(current);
      case 'skipForward':
        return forwardFrameIndex(current, 10);
      case 'skipBackward':
        return backwardFrameIndex(current, 10);
      case 'initial':
        return 0;
      default:
        throw new Error();
    }
  }, 0);

  const handleTogglePlayClick = () => {
    setIsPlaying((state) => !state);
  };

  const handleNextFrameClick = () => {
    changeFrameIndex('increment');
  };

  const skipForward = () => {
    changeFrameIndex('skipForward');
  };

  const skipBackward = () => {
    changeFrameIndex('skipBackward');
  };

  const handlePrevFrameClick = () => {
    changeFrameIndex('decrement');
  };

  const handleNextListClick = () => {
    changeFrameIndex('initial');
    changeListIndex('increment');
  };

  const handlePrevListClick = () => {
    changeFrameIndex('initial');
    changeListIndex('decrement');
  };

  const onFrameLoad = () => {
    setFrameIsLoaded(true);
    setFrameLoadError(false);
  };

  const onFrameLoadError = () => {
    setFrameIsLoaded(true);
    setFrameLoadError(true);
  };

  const handleKeyDown = useCallback((event) => {
    switch (event.code) {
      case 'ArrowRight':
        window.setTimeout(() => handleNextFrameClick());
        nextFrameButtonRef.current.focus();
        break;
      case 'ArrowLeft':
        window.setTimeout(() => handlePrevFrameClick());
        prevFrameButtonRef.current.focus();
        break;
      case 'ArrowUp':
        skipForward();
        break;
      case 'ArrowDown':
        skipBackward();
        break;
      case 'Space':
        handleTogglePlayClick();
        playPauseButtonRef.current.focus();
        break;
      default:
    }
  }, []); //eslint-disable-line

  const handleOnClickInfoFrame = () => {
    dispatch(setFrameDetailsOverlay(true));
  };

  const handleFullScreenModalClose = () => {
    setFullScreen(false);
  };

  const handleFullScreenModalOpen = () => {
    setFullScreen(true);
  };

  const FrameTime = () => (
    <div className={`${styles.frameTime} ${styles.dataBox}`} data-testid="frameTime">
      <p>{frameTimeDetails.date}</p>
      <p>{frameTimeDetails.time}</p>
    </div>
  );

  const Slider = () => (
    <div
      className={`
        ${styles.slider}
        ${fillParentHeight && !fullScreen ? styles.fillParent : ''}
        ${fullScreen ? styles.fullScreen : ''}
        ${frameLoadError ? styles.frameLoadError : ''}
        ${showOverlayContent ? styles.overlayContentVisible : ''}
        ${isLoadingFrames || showFrameSpinner ? styles.isLoading : ''}
        ${notFound ? styles.notFound : ''}
      `}
      ref={rootElementRef}
    >
      {showOverlayContent && <div className={styles.overlayContent}>{overlayContent}</div>}
      {!frames.length || (
        <>
          <img
            className={styles.frameImage}
            alt="Video frame"
            src={frames[frameIndex]?.frameUrl}
            onLoad={onFrameLoad}
            onError={onFrameLoadError}
            data-testid="frameImage"
            ref={frameImgRef}
          />
          {showBoxes && frameIsLoaded && !overlayContent && (
            <div className={styles.frameCanvas}>
              <FrameCanvas
                boundingBoxes={frames[frameIndex].boundingBoxes}
                tags={frames[frameIndex].tags}
                imgRef={frameImgRef}
              />
            </div>
          )}
        </>
      )}
      {(isLoadingFrames || !frames.length || notFound) && (
        <div className={styles.iconsOverlay}>
          {isLoadingFrames && !showNotFound && (
            <CircularProgress size={26} thickness={4} data-testid="framesLoadingIndicator" />
          )}
          {!frames.length && !isLoadingFrames && !notFound && (
            <VideoIcon className="icon-wrapper" data-testid="noFramesIcon" />
          )}
          {notFound && (
            <img
              src={imageNotAvailable}
              alt="Not available"
              data-testid="imageNotAvailable"
              className={styles.imageNotAvailable}
            />
          )}
        </div>
      )}
      <div className={styles.toolsOverlay}>
        {showFrameSpinner && <CircularProgress className={styles.imageFrameLoadingIndicator} size={26} thickness={4} />}
        <div className={styles.topBar}>
          <div className={styles.leftCol}>
            {showFrameDetails && framesLists.length && fullScreen && (
              <div className={`${styles.dataBox} ${styles.darkBox}`} data-testid="listNumber">
                {`${listIndex + 1} / ${framesLists.length}`}
              </div>
            )}
            {showFrameDetails && frames.length && (
              <div className={`${styles.frameCounter} ${styles.dataBox}`} data-testid="frameNumber">
                {`${frameIndex + 1} / ${frames.length}`}
              </div>
            )}
          </div>
          <div className={styles.rightCol}>
            {showInfoButton && isUserAdmin && (
              <button
                type="button"
                className={`${styles.infoButton} ${styles.dataBox}`}
                data-testid="infoButton"
                onClick={handleOnClickInfoFrame}
              >
                <InfoOutlined />
              </button>
            )}
            {fullScreen ? (
              <button
                type="button"
                className={`${styles.fullScreenButton} ${styles.dataBox}`}
                data-testid="exitFullScreenButton"
                onClick={handleFullScreenModalClose}
              >
                <FullscreenExit />
              </button>
            ) : (
              !notFound && (
                <button
                  type="button"
                  className={`${styles.fullScreenButton} ${styles.dataBox}`}
                  data-testid="enterFullScreenButton"
                  onClick={handleFullScreenModalOpen}
                >
                  <Fullscreen />
                </button>
              )
            )}
          </div>
        </div>
        <div className={styles.mainBar}>
          {showTags && fullScreen && isUserAdmin && (
            <div className={`${styles.tags} ${styles.dataBox}`}>
              {frames[frameIndex]?.tags.map((tag) => (
                <span className={styles.tag} key={tag}>
                  {tag}
                </span>
              ))}
            </div>
          )}
        </div>
        <div className={styles.bottomBar}>
          <div className={`${styles.col} ${styles.firstcol}`}>
            {showFrameDetails && !notFound && frameTimePosition === 'left' && <FrameTime />}
          </div>
          <div className={`${styles.col} ${styles.mainCol}`}>
            {framesLists.length > 1 && (
              <button
                type="button"
                className={`${styles.control} ${styles.prevListButton} ${styles.dataBox}`}
                onClick={() => {
                  handlePrevListClick();
                }}
                data-testid="prevListButton"
              >
                <PlayCircleFilled />
              </button>
            )}
            <button
              type="button"
              className={`${styles.control} ${styles.prevFrameButton} ${styles.dataBox}`}
              onClick={() => {
                handlePrevFrameClick();
              }}
              ref={prevFrameButtonRef}
              data-testid="prevFrameButton"
            >
              <FastForward />
            </button>
            <button
              type="button"
              className={`${styles.control} ${styles.dataBox}`}
              onClick={() => {
                handleTogglePlayClick();
              }}
              ref={playPauseButtonRef}
              data-testid="playPauseButton"
            >
              {isPlaying ? <Pause /> : <PlayArrow />}
            </button>
            <button
              type="button"
              className={`${styles.control} ${styles.dataBox}`}
              onClick={() => {
                handleNextFrameClick();
              }}
              ref={nextFrameButtonRef}
              data-testid="nextFrameButton"
            >
              <FastForward />
            </button>
            {framesLists.length > 1 && (
              <button
                type="button"
                className={`${styles.control} ${styles.dataBox}`}
                onClick={() => {
                  handleNextListClick();
                }}
                data-testid="nextListButton"
              >
                <PlayCircleFilled />
              </button>
            )}
          </div>
          <div className={`${styles.col} ${styles.lastCol}`}>
            {bottomRightContent}
            {showFrameDetails && !notFound && frameTimePosition === 'right' && <FrameTime />}
          </div>
        </div>
      </div>
    </div>
  );

  useLayoutEffect(() => {
    clearTimeout(nextFrameTimeout.current);
  }, [isPlaying, listIndex, frames, frameIsLoaded]);

  useLayoutEffect(() => {
    if (frameIsLoaded) {
      setShowFrameSpinner(false);
      clearTimeout(showFrameSpinnerTimeout.current);
      if (isPlaying) {
        nextFrameTimeout.current = setTimeout(() => changeFrameIndex('increment'), 1500);
      }
    } else {
      showFrameSpinnerTimeout.current = setTimeout(() => setShowFrameSpinner(true), 200);
    }
  }, [frameIsLoaded, isPlaying]); //eslint-disable-line

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (listIndexChangedHandler) {
      listIndexChangedHandler(listIndex);
    }
  }, [listIndex]); //eslint-disable-line

  useEffect(() => {
    changeFrameIndex('initial');
  }, [frames]);

  useEffect(() => {
    changeListIndex(0);
  }, [framesLists]);

  useEffect(() => {
    changeFrameIndex('initial');
    changeListIndex(_listIndex);
  }, [_listIndex]);

  useEffect(() => {
    if (!frames.length) return;
    preloadImage(frames[forwardFrameIndex(frameIndex)]?.frameUrl);
    preloadImage(frames[backwardFrameIndex(frameIndex)]?.frameUrl);
  }, [frameIndex, frames]); //eslint-disable-line

  useEffect(() => {
    if (showFrameDetails && frames.length > 0 && frames[frameIndex]) {
      const dateString = frames[frameIndex].frameTime;

      setFrameTimeDetails({
        date: fromUtcToProjectTime(dateString, 'ISO_DATE').split(' ')[0],
        time: fromUtcToProjectTime(dateString, 'ISO_TIME'),
      });
    }
  }, [frameIndex, frames, showFrameDetails]); //eslint-disable-line

  useEffect(() => {
    setIsPlaying(!overlayContent);
  }, [overlayContent]);

  useEffect(() => {
    if (frames.length && frames[frameIndex]) {
      const { lat: latPrev, lon: lonPrev } = frames?.[backwardFrameIndex(frameIndex)];
      const { lat: latCurr, lon: lonCurr } = frames[frameIndex];
      const activeFrameCoordsPayload = {
        prev: { latPrev, lonPrev },
        curr: { latCurr, lonCurr },
      };

      setFrameIsLoaded(false);
      dispatch(
        process.env.REACT_APP_MAP_GENERAL_VIEW === 'mapbox'
          ? setActiveFrameCordsAsync(activeFrameCoordsPayload)
          : setActiveFrameCords(activeFrameCoordsPayload),
      );
      dispatch(setActiveFrameDetails(frames[frameIndex]));
    }
  }, [frameIndex, listIndex, frames]); //eslint-disable-line

  return fullScreen ? (
    <Modal
      className={styles.fullScreenModal}
      open={fullScreen}
      onClose={handleFullScreenModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        className: styles.fullScreenModalBackdrop,
      }}
    >
      <Fade in={fullScreen}>
        <>
          <Slider />
        </>
      </Fade>
    </Modal>
  ) : (
    <Slider />
  );
};

FramesSlider.propTypes = {
  listIndex: propTypes.number,
  framesLists: propTypes.array.isRequired,
  listIndexChangedHandler: propTypes.func,
  isLoading: propTypes.bool,
  showFrameDetails: propTypes.bool,
  showInfoButton: propTypes.bool,
  frameTimePosition: propTypes.string,
  fillParentHeight: propTypes.bool,
  showNotFound: propTypes.bool,
  overlayContent: propTypes.node,
  showBoxes: propTypes.bool,
  showTags: propTypes.bool,
  bottomRightContent: propTypes.node,
};

FramesSlider.defaultProps = {
  listIndex: 0,
  listIndexChangedHandler: null,
  isLoading: false,
  showFrameDetails: false,
  showInfoButton: false,
  fillParentHeight: false,
  showNotFound: false,
  frameTimePosition: 'right',
  overlayContent: null,
  showBoxes: false,
  showTags: false,
  bottomRightContent: null,
};
