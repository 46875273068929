import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Grid } from '@material-ui/core';
import { Map as MapIcon } from '@material-ui/icons';
import { BorderlessButton, StackedVerticalBarChart } from 'components';
import {
  selectQueueOccupancies,
  selectQueueOccupanciesFiller,
  selectQueueTravelRatio,
  selectQueueTravelRatioFiller,
  setStatisticType,
} from 'features';
import StatisticsCardStyles from './StatisticsCard.styles';

const useStyles = makeStyles(StatisticsCardStyles);

export const StatisticsCard = ({ generalStatisticType, pathname, children, disabled, hasPopup, dataTestId }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const queueOccupancies = useSelector(selectQueueOccupancies(generalStatisticType));
  const queueOccupanciesFiller = useSelector(selectQueueOccupanciesFiller(generalStatisticType));
  const queueTravelRatio = useSelector(selectQueueTravelRatio(generalStatisticType));
  const queueTravelRatioFiller = useSelector(selectQueueTravelRatioFiller(generalStatisticType));
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const hidePopup = () => {
    setIsPopupVisible(false);
  };

  const navigateToPathname = () => {
    dispatch(setStatisticType(generalStatisticType));
    history.push({ pathname });
  };

  return (
    <Grid item xs={12} sm={6}>
      <div
        onMouseEnter={() => hasPopup && !disabled && showPopup()}
        onMouseLeave={() => hasPopup && !disabled && hidePopup()}
        onClick={() => !hasPopup && !disabled && navigateToPathname()}
        className={`${classes.cardWrapper} ${!hasPopup && !disabled ? classes.clickable : ''}`}
        data-testid={dataTestId}
      >
        {disabled && <div className={classes.disabledOverlay} />}
        {children}
        <div className={`${classes[generalStatisticType]} ${isPopupVisible ? 'notVisible' : ''}`} />
        <div className={`${classes.popUp} ${isPopupVisible ? 'visible' : ''}`}>
          <div className={classes.header}>{children}</div>
          <div className={classes.chartsWrapper}>
            <StackedVerticalBarChart
              legendText={<FormattedMessage description="Vertical bar chart title" defaultMessage="Traffic Density" />}
              firstChartData={queueOccupancies}
              secondChartData={queueOccupanciesFiller}
            />
            <StackedVerticalBarChart
              legendText={
                <FormattedMessage description="Vertical bar chart title" defaultMessage="Traffic Queue Time" />
              }
              firstChartData={queueTravelRatio}
              secondChartData={queueTravelRatioFiller}
            />
          </div>
          <div className={classes.footer}>
            <BorderlessButton
              title={<FormattedMessage description="Go to map button" defaultMessage="Go to map" />}
              startIcon={<MapIcon />}
              onClick={navigateToPathname}
            />
          </div>
        </div>
      </div>
    </Grid>
  );
};

StatisticsCard.propTypes = {
  generalStatisticType: propTypes.string.isRequired,
  pathname: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
  disabled: propTypes.bool,
  hasPopup: propTypes.bool,
  dataTestId: propTypes.string,
};

StatisticsCard.defaultProps = {
  disabled: false,
  hasPopup: false,
  dataTestId: undefined,
};
