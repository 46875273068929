/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import propTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

export const ProtectedRoute = ({ component: Component, publicRoute, isAuthorized, ...rest }) => {
  const render = (props) => {
    if (isAuthorized) {
      return <Component {...props} />;
    }

    return <Redirect to={{ pathname: publicRoute }} />;
  };

  return <Route {...rest} render={render} />;
};

ProtectedRoute.propTypes = {
  component: propTypes.func.isRequired,
  publicRoute: propTypes.string.isRequired,
  isAuthorized: propTypes.bool.isRequired,
};
