import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { planRangeToText } from 'utils';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Collapse,
  LinearProgress,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Add, Remove, RadioButtonUnchecked, RadioButtonChecked } from '@material-ui/icons';
import { ReactComponent as ProgressCheck } from 'assets/images/Progress Check@3x.svg';
import { selectSimulations, setCurrentSimulationId, loadSimulation, setExpandedPanel, selectPlanRange } from 'features';
import { ActionButton } from 'components';
import DigitalTwinStyles from './DigitalTwin.styles';

const useStyles = makeStyles(DigitalTwinStyles);

export const DigitalTwin = ({ selectionData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const simulations = useSelector(selectSimulations);
  const selectedPlanRange = useSelector(selectPlanRange).first;
  const [isOptimizeExpanded, setOptimizedExpanded] = useState(false);
  const [reduceDelayTime, setReduceDelayTime] = useState(true);
  const [reduceEmissions, setReduceEmissions] = useState(false);
  const [publicTransportPriority, setPublicTransportPriority] = useState(true);
  const [pedestriansPriority, setPedestriansPriority] = useState(false);

  const handleGenerateSimulationClick = () => {
    dispatch(loadSimulation(selectionData.corridor.corridorName));
  };

  const onShowSimResultsClick = (id) => () => {
    dispatch(setCurrentSimulationId(id));
    dispatch(setExpandedPanel('simulation'));
  };

  return (
    <>
      <Box className={classes.optimizeContainerTitle} onClick={() => setOptimizedExpanded(!isOptimizeExpanded)}>
        <ProgressCheck className={classes.digitalTwinHeaderIcon} />
        <Typography paragraph className={classes.optimizeTitle}>
          <FormattedMessage defaultMessage="Digital Twin" description="Digital Twin title" />
        </Typography>
        {isOptimizeExpanded ? <Remove color="primary" /> : <Add color="primary" />}
      </Box>
      <Collapse in={isOptimizeExpanded} classes={{ container: classes.collapseContainer }}>
        {simulations.loading === 'loading' ? <LinearProgress className={classes.simulationLinearProgress} /> : <hr />}
        <Box className={classes.optimizeContainer}>
          {simulations.loading === 'loading' && (
            <>
              <div className={classes.simulationContainer}>
                <Typography paragraph>
                  <FormattedMessage defaultMessage="Simulating..." description="Digital Twin Simulating..." />
                </Typography>
                <Typography paragraph className={classes.mb0}>
                  {`${selectionData.corridor.corridorName} corridor`}
                </Typography>
                <Typography paragraph className={classes.mb0}>
                  <span className={classes.bold}>
                    <FormattedMessage defaultMessage="START" description="Digital Twin START" />
                  </span>
                  {`: ${selectionData.start}`}
                </Typography>
                <Typography paragraph className={classes.mb0}>
                  <span className={classes.bold}>
                    <FormattedMessage defaultMessage="END" description="Digital Twin END" />
                  </span>
                  {`: ${selectionData.end}`}
                </Typography>
                <Typography paragraph className={classes.mb0}>
                  {planRangeToText(selectedPlanRange)}
                </Typography>
              </div>
            </>
          )}

          <>
            <div className={`${classes.simulationContainer} ${classes.visuallyHidden}`}>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked />}
                        color="primary"
                        checked={reduceDelayTime}
                        onChange={(event) => setReduceDelayTime(event.target.checked)}
                        name="reduceDelayTime"
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        <FormattedMessage
                          defaultMessage="Reduce delay time"
                          description="Digital Twin Reduce delay time"
                        />
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked />}
                        color="primary"
                        checked={reduceEmissions}
                        onChange={(event) => setReduceEmissions(event.target.checked)}
                        name="reduceEmissions"
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        <FormattedMessage
                          defaultMessage="Reduce emissions"
                          description="Digital Twin Reduce emissions"
                        />
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked />}
                        color="primary"
                        checked={publicTransportPriority}
                        onChange={(event) => setPublicTransportPriority(event.target.checked)}
                        name="publicTransportPriority"
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        <FormattedMessage
                          defaultMessage="Public transportation priority"
                          description="Digital Twin Public transportation priority"
                        />
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked />}
                        color="primary"
                        checked={pedestriansPriority}
                        onChange={(event) => setPedestriansPriority(event.target.checked)}
                        name="pedestriansPriority"
                      />
                    }
                    label={
                      <Typography className={classes.formControlLabel}>
                        <FormattedMessage
                          defaultMessage="Pedestrians priority"
                          description="Digital Twin Pedestrians priority"
                        />
                      </Typography>
                    }
                  />
                </FormGroup>
              </FormControl>
            </div>
            {simulations.loading !== 'loading' && (
              <ActionButton
                onClick={handleGenerateSimulationClick}
                buttonTitle={<FormattedMessage description="Generate button" defaultMessage="Generate" />}
              />
            )}
            {simulations.loading === 'loaded' &&
              simulations.ids.map((id) => (
                <Button
                  key={id}
                  variant="contained"
                  className={classes.searchButton}
                  onClick={onShowSimResultsClick(id)}
                >
                  <FormattedMessage defaultMessage="Show results for:" description="Digital Twin Show results for:" />
                  {id.split('-')[3]}
                </Button>
              ))}
            {simulations.loading === 'error' && <p className={classes.optimizationError}>{simulations.error}</p>}
          </>
        </Box>
      </Collapse>
    </>
  );
};

DigitalTwin.propTypes = {
  selectionData: propTypes.object.isRequired,
};
