import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectCorridors } from 'features/corridorsSlice';
import {
  loadPhaseDiagram,
  loadPrograms,
  selectPrograms,
  selectTimings,
  selectPhaseDiagrams,
  selectExpandedPanel,
} from 'features';
import { centerMapOnIntersection, highlightIntersections } from 'utils/map';
import { PhaseDiagram, PhaseDiagramSelect, OperatingSchedule, BottomContainer, SignalPlansTable } from 'components';
import { Map as MapIcon, Timeline, Schedule as ScheduleIcon } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import classes from './SignalPlansContainer.module.scss';

export const SignalPlansContainer = () => {
  const dispatch = useDispatch();
  const junctionMapRef = useRef();
  const corridors = useSelector(selectCorridors);
  const { programs, programsLoading } = useSelector(selectPrograms);
  const timings = useSelector(selectTimings);
  const { phaseDiagrams, phaseDiagramLoading } = useSelector(selectPhaseDiagrams);
  const expanded = useSelector(selectExpandedPanel);
  const [activeMediaTab, setActiveMediaTab] = useState('junction');
  const [activeStop, setActiveStop] = useState(null);
  const [activeProgram, setActiveProgram] = useState(null);
  const [isJunctionMapLoading, setIsJunctionMapLoading] = useState(false);
  const phaseDiagramData = phaseDiagrams[activeStop?.id]?.[activeProgram];
  const programsData = programs[activeStop?.id];
  const timingsData = timings[activeStop?.id];
  const { ids, selectedCorridorIndex } = corridors;
  const mediaTabs = [
    {
      id: 'junction',
      text: <FormattedMessage description="Junction map tab" defaultMessage="Junction map" />,
      icon: <MapIcon />,
    },
    {
      id: 'phase',
      text: <FormattedMessage description="Phase diagram tab" defaultMessage="Phase diagram" />,
      icon: <Timeline />,
    },
    {
      id: 'operating',
      text: <FormattedMessage description="Operating schedule tab" defaultMessage="Operating schedule" />,
      icon: <ScheduleIcon />,
    },
  ];

  const centerOnIntersection = () => {
    centerMapOnIntersection({ intersection: activeStop.properties.name, minRes: 3, padding: [0, 0, 300, 300] });
  };

  const handleJunctionMapLoaded = () => {
    setIsJunctionMapLoading(false);
    junctionMapRef.current.style.display = 'block'; // eslint-disable-line
  };

  const handleProgramSelect = (event) => {
    setActiveProgram(event.target.value);
  };

  useEffect(() => {
    if (!activeStop) {
      return;
    }
    dispatch(
      loadPrograms({
        corridorId: ids[selectedCorridorIndex],
        stopId: activeStop.id,
      }),
    );
    centerOnIntersection(activeStop);
    setIsJunctionMapLoading(true);
    junctionMapRef.current.style.display = 'none';
    junctionMapRef.current.src = `${process.env.REACT_APP_API_BASE_URL}/map/${ids[selectedCorridorIndex]}/${activeStop.id}?background=true`;
  }, [activeStop]); // eslint-disable-line

  useEffect(() => {
    if (expanded === 'signal-plan' && activeStop) {
      highlightIntersections([activeStop.id]);
      centerOnIntersection(activeStop);
    } else {
      highlightIntersections(null);
    }
  }, [expanded, activeStop]); // eslint-disable-line

  useEffect(() => {
    if (!programs[activeStop?.id]) {
      return;
    }
    setActiveProgram(programsData[0].number);
  }, [activeStop, programs[activeStop?.id]]); //eslint-disable-line

  useEffect(() => {
    if (!activeStop || activeProgram === null) {
      return;
    }

    dispatch(
      loadPhaseDiagram({
        corridorId: ids[selectedCorridorIndex],
        stopId: activeStop.id,
        programNumber: activeProgram,
      }),
    );
  }, [activeStop, activeProgram]); // eslint-disable-line

  return (
    <BottomContainer
      slideIn={expanded === 'signal-plan'}
      mediaTabs={mediaTabs}
      activeMediaTab={activeMediaTab}
      setActiveMediaTab={setActiveMediaTab}
      mainContent={<SignalPlansTable setSignalPlansActiveStop={setActiveStop} />}
      isLoadingMediaContent={
        (activeMediaTab === 'junction' && isJunctionMapLoading) ||
        (activeMediaTab === 'phase' && (phaseDiagramLoading !== 'loaded' || programsLoading !== 'loaded')) ||
        (activeMediaTab === 'operating' && !timingsData)
      }
      mediaContent={
        <>
          <img
            ref={junctionMapRef}
            alt="Junction map"
            onLoad={handleJunctionMapLoaded}
            onError={handleJunctionMapLoaded}
            className={`
            ${classes.junctionMap}
            ${activeMediaTab === 'junction' ? '' : classes.junctionMapHidden}
          `}
          />
          {activeMediaTab === 'phase' &&
            programsData &&
            (phaseDiagramData || activeProgram !== programsData[0].number) && (
              <Box className={classes.phaseDiagramContainer}>
                <Box className={classes.phaseDiagramSelect}>
                  <PhaseDiagramSelect
                    {...{
                      programsData,
                      activeProgram,
                      handleProgramSelect,
                    }}
                  />
                </Box>
                <Box className={classes.phaseDiagram}>
                  <PhaseDiagram phaseDiagramData={phaseDiagramData} size="small" hasZoom />
                </Box>
              </Box>
            )}
          {activeMediaTab === 'operating' && timingsData && (
            <OperatingSchedule
              timings={timingsData}
              setActiveMediaTab={setActiveMediaTab}
              setActiveProgram={setActiveProgram}
            />
          )}
        </>
      }
    />
  );
};
