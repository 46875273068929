import React, { useState } from 'react';
import propTypes from 'prop-types';
import { CircularProgress, Button } from '@material-ui/core';
import { API_BASE_URL, LOG_IN } from 'consts';
import { FormattedMessage } from 'react-intl';
import classes from './LoginOverlay.module.scss';

export const LoginOverlay = ({ isVisible }) => {
  const [inProgress, setInProgress] = useState(false);

  const onLoginClick = () => {
    setInProgress(true);
    window.location.assign(`${API_BASE_URL}/${LOG_IN}`);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classes.overlay}>
      <h1 className={classes.headline}>
        <strong className={classes.headlineX}>X</strong>
        {'WAY '}
      </h1>
      <p className={classes.subhead}>
        <FormattedMessage
          defaultMessage="Reduce Pollution. Reduce Congestion. Increase Safety."
          description="Login overlay title 1"
        />
        <strong>
          <FormattedMessage defaultMessage="TODAY." description="Login overlay title 2" />
        </strong>
      </p>
      {inProgress ? (
        <CircularProgress className={classes.spinner} size={60} thickness={5} />
      ) : (
        <Button className={classes.loginButton} onClick={onLoginClick} variant="contained" color="primary">
          <FormattedMessage defaultMessage="Log in to continue" description="Login overlay login button" />
        </Button>
      )}
    </div>
  );
};

LoginOverlay.propTypes = {
  isVisible: propTypes.bool,
};

LoginOverlay.defaultProps = {
  isVisible: true,
};
