import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { selectStatisticType, selectIsUserAdmin } from 'features';
import { statisticTypes } from 'consts';

import styles from './ListMenu.module.scss';

export const ListMenu = ({
  type,
  scheme,
  items,
  initialItem,
  activationHandler,
  className,
  tooltipStyle,
  hiddenMenus,
  fullWidthItems,
}) => {
  const statisticType = useSelector(selectStatisticType);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const [activeItem, setActiveItem] = useState(initialItem || items[0]);

  const handleClick = (index) => {
    setActiveItem(items[index]);
  };

  useLayoutEffect(() => {
    if (initialItem !== activeItem) {
      setActiveItem(initialItem || items[0]);
    }
    if (hiddenMenus.length > 0 && statisticType === statisticTypes.PEDESTRIANS && !isUserAdmin) {
      setActiveItem(items[hiddenMenus.length]);
    }
  }, [items, initialItem, isUserAdmin]); //eslint-disable-line

  useEffect(() => {
    if (activationHandler) {
      activationHandler(activeItem);
    }
  }, [activeItem]); //eslint-disable-line

  return (
    <div
      className={`
        ${styles.root}
        ${styles[`type-${type}`]}
        ${scheme ? styles[`scheme-${scheme}`] : ''}
        ${className || ''}
      `}
    >
      {items.map(({ name, text, icon, tooltip, disabled, className: buttonClass }, index) => (
        <Tooltip
          title={tooltip || ''}
          placement="top"
          key={name}
          disableHoverListener={!tooltip}
          disableFocusListener={!tooltip}
          disableTouchListener={!tooltip}
          classes={{ tooltip: tooltipStyle }}
        >
          <button
            className={`
            ${buttonClass || ''}
            ${styles.item}
            ${items[index] === activeItem ? 'active' : ''}
            ${hiddenMenus.includes(name) ? 'hidden' : ''}
          `}
            type="button"
            disabled={disabled}
            onClick={() => !disabled && handleClick(index)}
            {...(items[index] === activeItem && { tabIndex: '-1' })}
            style={{ minWidth: fullWidthItems ? `${100 / items.length}%` : 'unset' }}
          >
            {icon && <span className={styles.iconBox}>{icon}</span>}
            {text && <span className={styles.text}>{text}</span>}
          </button>
        </Tooltip>
      ))}
    </div>
  );
};

ListMenu.propTypes = {
  type: propTypes.string.isRequired,
  scheme: propTypes.string,
  items: propTypes.array.isRequired,
  initialItem: propTypes.object,
  activationHandler: propTypes.func,
  className: propTypes.string,
  tooltipStyle: propTypes.string,
  hiddenMenus: propTypes.array,
  fullWidthItems: propTypes.bool,
};

ListMenu.defaultProps = {
  scheme: 'default',
  initialItem: null,
  activationHandler: null,
  className: '',
  tooltipStyle: '',
  hiddenMenus: [],
  fullWidthItems: false,
};
