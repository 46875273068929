import React, { useState, useEffect, useMemo } from 'react';
import { ListMenu } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  selectDaysInPast,
  selectTimeZone,
  setDaysInPast,
  setStartDate,
  selectStartDate,
  selectIsUserAdmin,
  selectFilterNumberOfDays,
  selectFilterNumberOfSamples,
  setFilterNumberOfDays,
  setFilterNumberOfSamples,
  selectPlanRange,
  setPlanRange,
  selectTimeIntervals,
} from 'features';
import { useFormat, useIntl } from 'utils/hooks';
import { CircularProgress } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import BrightnessLowIcon from '@material-ui/icons/BrightnessLow';
import BrightnessMediumIcon from '@material-ui/icons/BrightnessMedium';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import styles from './GeneralViewTimeSelect.module.scss';

const tabsData = {
  other_time: {
    text: <FormattedMessage defaultMessage="Other Time" description="General View time switcher: Other" />,
    icon: <NightsStayIcon />,
  },
  morning_rush_hour: {
    text: <FormattedMessage defaultMessage="Morning Rush Hour" description="General View time switcher: Morning" />,
    icon: <BrightnessLowIcon />,
  },
  midday: {
    text: <FormattedMessage defaultMessage="Midday" description="General View time switcher: Midday" />,
    icon: <BrightnessHighIcon />,
  },
  evening_rush_hour: {
    text: <FormattedMessage defaultMessage="Evening Rush Hour" description="General View time switcher: Evening" />,
    icon: <BrightnessMediumIcon />,
  },
};

export const GeneralViewTimeSelect = () => {
  const intl = useIntl();
  const { fromClientToProjectTime } = useFormat();
  const dispatch = useDispatch();
  const timeIntervals = useSelector(selectTimeIntervals);
  const currentPlan = useSelector(selectPlanRange).first;
  const daysInPast = useSelector(selectDaysInPast);
  const startDate = useSelector(selectStartDate);
  const timeZone = useSelector(selectTimeZone);
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const filterNumberOfDays = useSelector(selectFilterNumberOfDays);
  const filterNumberOfSamples = useSelector(selectFilterNumberOfSamples);
  const currentPlanRanges = useSelector(selectPlanRange);
  const [isDaysPanelExpanded, setIsDaysPanelExpanded] = useState(false);
  const [isPickerOpen, setIsPickerOpen] = useState(false);

  const tabs = useMemo(() => {
    if (!timeIntervals.length) {
      return [];
    }
    const indexPlanOther = timeIntervals.findIndex(({ label }) => label === 'other_time');
    const orderedIntervals = [...timeIntervals];

    orderedIntervals.push(orderedIntervals.splice(indexPlanOther, 1)[0]);

    return orderedIntervals.map(({ label, startTime, endTime }) => ({
      ...tabsData[label],
      name: label,
      tooltip: `${startTime}-${endTime}`,
    }));
  }, [timeIntervals]);

  const initialTab = tabs.find((tab) => tab.name === currentPlan?.label);

  const onTabChange = (tab) => {
    if (!timeIntervals.length) return;
    dispatch(
      setPlanRange({
        first: timeIntervals.find((interval) => interval.label === tab.name),
      }),
    );
  };

  const onDaysInPastChange = (event) => {
    dispatch(setDaysInPast(event.target.value));
  };

  const onStartDateChange = (event) => {
    dispatch(setStartDate(+event));
  };

  const onFilterDaysChange = (event) => {
    dispatch(setFilterNumberOfDays(event.target.value));
  };

  const onFilterSamplesChange = (event) => {
    dispatch(setFilterNumberOfSamples(event.target.value));
  };

  const onDaysPanelToggleClick = () => {
    setIsDaysPanelExpanded(!isDaysPanelExpanded);
  };

  useEffect(() => {
    if (timeIntervals.length && !timeIntervals.find(({ label }) => label === currentPlanRanges.first?.label)) {
      dispatch(
        setPlanRange({
          first: timeIntervals.find((interval) => interval.default),
        }),
      );
    }
  }, [timeIntervals]); //eslint-disable-line

  return (
    <div className={styles.root}>
      <div
        className={`
            ${styles.daysPanel}
            ${isDaysPanelExpanded ? styles.daysPanelExpanded : ''}
            ${isUserAdmin ? styles.adminPanel : ''}
          `}
      >
        <button className={styles.daysPanelToggleButton} type="button" onClick={onDaysPanelToggleClick}>
          <ArrowDropUpIcon className={styles.daysPanelToggleButtonIcon} />
        </button>
        <form className={styles.daysPanelContent} autoComplete="off">
          {!timeZone ? (
            <CircularProgress size={20} />
          ) : (
            <div className={styles.daysPanelItems}>
              <div className={styles.daysPanelItem}>
                <span className={styles.daysPanelLabel}>
                  {intl.formatMessage({
                    defaultMessage: 'Number of days in the past',
                    description: 'City view days select',
                  })}
                </span>
                <div className={styles.daysInput}>
                  <input type="number" value={daysInPast} min="1" onChange={onDaysInPastChange} />
                </div>
              </div>
              <div className={styles.daysPanelItem}>
                <span className={styles.daysPanelLabel}>
                  {intl.formatMessage({
                    defaultMessage: 'Start from date',
                    description: 'City view date select',
                  })}
                </span>
                <DatePicker
                  className={styles.datePickerHidden}
                  variant="inline"
                  disableToolbar
                  autoOk
                  initialFocusedDate={fromClientToProjectTime(startDate, 'ISO_DATE')}
                  maxDate={fromClientToProjectTime(new Date(), 'ISO_DATE')}
                  onChange={onStartDateChange}
                  open={isPickerOpen}
                  onOpen={() => setIsPickerOpen(true)}
                  onClose={() => setIsPickerOpen(false)}
                />
                <div className={styles.datePickerInput} onClick={() => setIsPickerOpen(true)}>
                  <ArrowDropUpIcon className={styles.datePickerArrow} />
                  {fromClientToProjectTime(startDate, 'DATE_MED')}
                </div>
              </div>
              {isUserAdmin && (
                <>
                  <div className={styles.daysPanelItem}>
                    <span className={styles.daysPanelLabel}>
                      {intl.formatMessage({
                        defaultMessage: 'Days',
                        description: 'City view date select',
                      })}
                    </span>
                    <div className={styles.daysInput}>
                      <input type="number" value={filterNumberOfDays} min="1" onChange={onFilterDaysChange} />
                    </div>
                  </div>
                  <div className={styles.daysPanelItem}>
                    <span className={styles.daysPanelLabel}>
                      {intl.formatMessage({
                        defaultMessage: 'Samples',
                        description: 'City view date select',
                      })}
                    </span>
                    <div className={styles.daysInput}>
                      <input type="number" value={filterNumberOfSamples} min="1" onChange={onFilterSamplesChange} />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </form>
      </div>
      <ListMenu
        type="switch"
        items={tabs}
        initialItem={initialTab}
        activationHandler={onTabChange}
        {...(isDaysPanelExpanded && { tooltipStyle: styles.listMenuTooltip })}
        fullWidthItems
      />
    </div>
  );
};
