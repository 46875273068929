import React, { useState, useLayoutEffect } from 'react';
import propTypes from 'prop-types';
import { ListMenu } from 'components';
import styles from './TabbedMenus.module.scss';

export const TabbedMenus = ({
  tabs,
  menus,
  activeTabHandler,
  activeMenuHandler,
  equalHeights,
  initialTab: _initialTab,
  hiddenMenus,
}) => {
  const initialTab = _initialTab || tabs[0];
  const [menuItems, setMenuItems] = useState(menus[initialTab.name]);
  const [menuHeight, setMenuHeight] = useState('auto');

  const onTabActivation = (tab) => {
    setMenuItems(menus[tab.name]);
    if (activeTabHandler) {
      activeTabHandler(tab);
    }
  };

  useLayoutEffect(() => {
    if (!equalHeights) return;

    const maxMenuItemsCount = Math.max(...Object.values(menus).map(({ length }) => length));
    const height = maxMenuItemsCount * 70;

    setMenuHeight(height);
  }, []); //eslint-disable-line

  return (
    <>
      <ListMenu type="tabs" items={tabs} initialItem={initialTab} activationHandler={onTabActivation} />
      <div className={styles.content} style={{ height: menuHeight }}>
        <ListMenu type="basic" items={menuItems} activationHandler={activeMenuHandler} hiddenMenus={hiddenMenus} />
      </div>
    </>
  );
};

TabbedMenus.propTypes = {
  tabs: propTypes.array.isRequired,
  menus: propTypes.object.isRequired,
  activeMenuHandler: propTypes.func.isRequired,
  activeTabHandler: propTypes.func,
  equalHeights: propTypes.bool,
  initialTab: propTypes.object,
  hiddenMenus: propTypes.array,
};

TabbedMenus.defaultProps = {
  activeTabHandler: null,
  equalHeights: true,
  initialTab: null,
  hiddenMenus: [],
};
