import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RawIntlProvider } from 'react-intl';
import { ProtectedRoute, NavigationDrawer, LoginOverlay, ToastMessage, LoadingOverlay } from 'components';
import {
  fetchSettings,
  fetchUser,
  selectIsUserAdmin,
  selectIsLoggedIn,
  selectTenants,
  selectIsUserLoaded,
  selectTimeZone,
  setDateRange,
  setUnitSystem,
  selectLocale,
  setPlanRange,
  fetchTimeIntervals,
  selectTimeIntervals,
  selectLoadingEditTimeIntervals,
  resetLoadingEditTimeIntervals,
  selectCanRunSimulations,
} from 'features';
import {
  Tenants,
  AdminData,
  GeneralView,
  HomeView,
  NotFound404Page,
  TrafficInsights,
  About,
  EditGrid,
  SimulationView,
} from 'screens';
import { handleResponseErrors, handleLoadingState } from 'utils';
import { Settings as DateTimeSettings } from 'luxon';
import { useIntl, useFormat } from 'utils/hooks';

const AppRouter = () => {
  const intl = useIntl();
  const { fromClientToProjectTime } = useFormat();
  const dispatch = useDispatch();
  const isUserAdmin = useSelector(selectIsUserAdmin);
  const canUserRunSimulations = useSelector(selectCanRunSimulations);
  const isUserLoaded = useSelector(selectIsUserLoaded);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userTenants = useSelector(selectTenants);
  const timeZone = useSelector(selectTimeZone);
  const locale = useSelector(selectLocale);
  const timeIntervals = useSelector(selectTimeIntervals);
  const loadingEditTimeIntervals = useSelector(selectLoadingEditTimeIntervals);
  const defaultTimeInterval = timeIntervals.find((interval) => interval.default);
  const hasTenant = userTenants?.currentTenant !== null;

  useEffect(() => {
    handleResponseErrors(intl);
    handleLoadingState();
    dispatch(fetchUser());
    dispatch(setUnitSystem({ intl, unitSystem: 'metric' }));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchSettings());
    }
  }, [isLoggedIn]); //eslint-disable-line

  useEffect(() => {
    if (isLoggedIn && hasTenant) {
      dispatch(fetchTimeIntervals());
    }
  }, [isLoggedIn, hasTenant]); //eslint-disable-line

  useEffect(() => {
    if (loadingEditTimeIntervals === 'loaded') {
      dispatch(fetchTimeIntervals());
      dispatch(resetLoadingEditTimeIntervals());
    }
  }, [loadingEditTimeIntervals]); //eslint-disable-line

  useEffect(() => {
    if (!timeZone) return;
    const date = fromClientToProjectTime(new Date());
    const dateRange = [date.startOf('day').minus({ weeks: 3 }).ts, date.endOf('day').ts];

    dispatch(
      setDateRange({
        first: dateRange,
        second: dateRange,
      }),
    );
  }, [timeZone]); // eslint-disable-line

  useEffect(() => {
    DateTimeSettings.defaultLocale = locale === 'en' ? 'en-GB' : locale;
  }, [locale]);

  useEffect(() => {
    if (timeIntervals.length) {
      dispatch(
        setPlanRange({
          first: defaultTimeInterval,
          second: defaultTimeInterval,
        }),
      );
    }
  }, [timeIntervals]); // eslint-disable-line

  return (
    <RawIntlProvider value={intl}>
      <Router>
        <LoadingOverlay />
        <ToastMessage />
        <NavigationDrawer />
        <LoginOverlay isVisible={!isLoggedIn && isUserLoaded} />
        <Switch>
          <ProtectedRoute
            path="/home"
            component={HomeView}
            publicRoute="/tenants"
            isAuthorized={!isUserAdmin || hasTenant}
          />
          <ProtectedRoute
            path="/general"
            component={GeneralView}
            publicRoute="/tenants"
            isAuthorized={!isUserAdmin || hasTenant}
          />
          <ProtectedRoute
            path="/corridors"
            component={TrafficInsights}
            publicRoute="/tenants"
            isAuthorized={!isUserAdmin || hasTenant}
          />
          <ProtectedRoute
            path="/simulation"
            component={SimulationView}
            publicRoute="/home"
            isAuthorized={canUserRunSimulations}
          />
          <ProtectedRoute path="/about" component={About} publicRoute="/about" isAuthorized={isUserAdmin} />
          <ProtectedRoute exact path="/tenants" component={Tenants} publicRoute="/" isAuthorized={isUserAdmin} />
          <ProtectedRoute
            path="/admin/all"
            component={AdminData}
            publicRoute="/home"
            isAuthorized={isUserAdmin && hasTenant}
          />
          <ProtectedRoute
            path="/admin/edit-grid"
            component={EditGrid}
            publicRoute="/home"
            isAuthorized={isUserAdmin && hasTenant}
          />
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route component={NotFound404Page} />
        </Switch>
      </Router>
    </RawIntlProvider>
  );
};

export default AppRouter;
