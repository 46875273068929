import React from 'react';
import propTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Arrow } from 'assets/images/arrow-direction.svg';
import CollapseButtonStyles from './CollapseButtonStyles.styles';

const useStyles = makeStyles(CollapseButtonStyles);

export const CollapseButton = ({ className, onClick, isPointingLeft }) => {
  const classes = useStyles();

  return (
    <div onClick={onClick} className={className}>
      <Arrow className={`${classes.collapseArrowIcon} ${isPointingLeft ? 'collapseArrowLeft' : ''}`} />
    </div>
  );
};

CollapseButton.propTypes = {
  className: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
  isPointingLeft: propTypes.bool.isRequired,
};
